import React from 'react';
import FeatherIcon from 'feather-icons-react';
import './App.css';
import Header from './containers/Header'
import Cover from './containers/cover'
import Who from './containers/Who_are'
import Drinks from './containers/Drinks'
import Food from './containers/Food'
import Branches from './containers/Branches'
import Draft from './containers/draft'
import Promos from './containers/Promos'
import NetBar from './containers/network-bar'
import Logof from './assets/images/logof.png'

import './assets/scss/index.scss'

class App extends React.Component {
  render(){
    return (
      <div className="App" style={{position:'relative'}}>
        <Header />
        <NetBar />
        <div style={{position:'relative'}}>
          <Cover />
          <Who />
          <Draft />
          <Drinks />
          <Food />
          <Promos />
          <Branches />
          {/* <Gallery /> */}
        </div>
        <div className='footer'>
            <div className='contFooter'>
                <p>Facturación</p>
            </div>
            <div className="socialNetworks">
              <a href="https://www.facebook.com/FiebreDeMalta" className="white">
                <FeatherIcon  icon='facebook' size='20' />
              </a>
              <a href="https://www.instagram.com/fiebre_de_malta" className="white">
                <FeatherIcon  icon='twitter' size='20' />
              </a>
              <a href="https://twitter.com/fiebredemalta" className="white">
                <FeatherIcon className='ig'  icon='instagram' size='20' />
              </a>
            </div> 
            <img src={Logof}/>
        </div>
      </div>
    );
  }
}

export default App;
