import React, {Component} from 'react'
import FeatherIcon from 'feather-icons-react';

class barNW extends Component{

    render(){
        return(
            <div className='networks'>
                <div className='logosNW'>
                    <a href="https://www.facebook.com/FiebreDeMalta" className="black">
                        <FeatherIcon  icon='facebook' size='20' />
                    </a>
                    <a href="https://www.instagram.com/fiebre_de_malta" className="black">
                        <FeatherIcon  icon='twitter' size='20' />
                    </a>
                    <a href="https://twitter.com/fiebredemalta" className="black">
                        <FeatherIcon className='ig'  icon='instagram' size='20' />
                    </a>
                </div>
                <div className='wordContact'>
                    <p className=''>Contacto</p>
                </div>
            </div>
        )
    }
}

export default barNW
