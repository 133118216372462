import React, { Component } from 'react';
import WhoCont from '../components/who_are'

class How_are extends Component {
  render() {
    return (
      <div id='who' className='containerWho parallax'>
        <WhoCont />
      </div>
    );
  }
}

export default How_are;
