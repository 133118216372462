import React from 'react';
import YouTube from 'react-youtube';

let cover;
export default cover = (props) => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
            showinfo: 0,
            loop: 1,
            controls: 0,
            modestbranding: 1,
            playlist: [
                'JOIJgz72JYU'
            ],
            disablekb: 1
        }
    };
    return (
        <div className='coverContent'>
            <YouTube className='videoFdm' videoId="JOIJgz72JYU" opts={opts} />
        </div>
    );
};
